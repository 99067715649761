import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import Image from '../components/Image';

const Horaires = props => (
  <Layout>
    <Helmet>
      <title>Horaires Entraînements - Palette Sartoise</title>
      <meta name="description" content="Horaires des entraînements" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Horaires des entraînements</h2>
          <p>
            Le club de la Palette Sartoise oragnise des entraînements de tennis de table deux fois par semaine à raison d’un entrainement dirigé par un entraîneur et d’un entraînement libre.
          </p>
        </header>

        <section id="content">
          <p>
            <Image alt="Horaires Entrainement - Palette Sartoise" filename="horaires-entrainement.jpg" />
          </p>
          <h3>Entraînements dirigés</h3>
          <p>
           Les entraînements dirigés du club se déroulent les <b>Mardis de 19h30 à 21h</b> sous la supervision d'un entraineur.
          </p>
          <h3>Entraînements libres</h3>
          <p>
            Les entraînements libres se déroulent quand à eux les <b>Mercredis de 19h30 à 21h</b>.
          </p>
          <h3>Compétition - Interclubs</h3>
          <p>
            Les interclubs se déroulent les <b>Samedis</b> à des horaires variables en fonction de la division et de l'adversaire.
          </p>  
        </section>
      </div>
    </div>
  </Layout>
)

export default Horaires
